<template>
  <div class="c-library__body">

    <div class="c-library__blog">
      <div class="c-library__blog-item" v-for="(dt, i) in blogList" :key="i" @click="openLink(dt.link)">
        <img class="c-library__blog-img" :src="dt.img" alt="blog-image" />
        <div class="c-library__blog-content">
          <h3 class="c-library__blog-title">{{ dt.title }}</h3>
          <p class="c-library__blog-detail">{{ dt.date }} | {{ dt.category }}</p>
        </div>
      </div>
    </div>
    <a href="https://blog.sparrowexchange.com/" target="_blank" class="c-library__blog-see-more">See all</a>
  </div>
</template>

<script>
export default {
  name: 'Blog',
  data() {
    return {
      blogList: [],
    }
  },
}
</script>
